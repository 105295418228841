<template>
    <v-container id="checkout" >
        <v-row v-if="cart.length > 0 || paid">
            <v-col cols="12" md="8">
                <section>
                    <v-stepper v-model="e1" elevation="0" rounded="0">
                        <v-stepper-header id="step-header" >
                            <v-stepper-step class="step" :complete="e1 > 1" step="1" color="none">
                                Datos de envío
                            </v-stepper-step>

                            <v-stepper-step class="step" :complete="e1 > 2" step="2" color="none">
                                Pago
                            </v-stepper-step>

                            <v-stepper-step class="step" step="3" color="none">
                                Resumen
                            </v-stepper-step>
                        </v-stepper-header>

                        <v-stepper-items id="step-content">
                            <v-stepper-content step="1">
                                <v-form ref="form" v-model="valid">
                                    <h2>Datos de contacto</h2>
                                    <v-row class="my-0">
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="firstName"
                                                :rules="required"
                                                label="Nombre"
                                                required
                                                color="#000000"
                                                :disabled="paid"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="lastName"
                                                :rules="required"
                                                label="Apellido"
                                                required
                                                color="#000000"
                                                :disabled="paid"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="my-0">
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="email"
                                                :rules="required"
                                                label="E-mail"
                                                required
                                                color="#000000"
                                                :disabled="paid"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="phoneNumber"
                                                :rules="required"
                                                label="Teléfono"
                                                required
                                                color="#000000"
                                                :disabled="paid"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <h2>Datos de entrega</h2>
                                    <v-row class="my-0">
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="streetAddress"
                                                :rules="required"
                                                label="Calle"
                                                required
                                                color="#000000"
                                                :disabled="paid"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="city"
                                                :rules="required"
                                                label="Ciudad"
                                                required
                                                color="#000000"
                                                :disabled="paid"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                     <v-row class="my-0">
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-select
                                                v-model="state"
                                                label="Estado"
                                                color="#000000"
                                                :items="states"
                                                item-text="state"
                                                :rules="requiredSelect"
                                                v-on:change = "calculateShipping"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="zipCode"
                                                :rules="required"
                                                label="Código Postal"
                                                required
                                                color="#000000"
                                                :disabled="paid"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-switch v-model="factura"
                                        :label="'¿Requieres factura?'"
                                        color="#000000"
                                        :disabled="paid"
                                        :ripple="false"
                                        :dense="true"
                                        v-if="false"
                                    ></v-switch>

                                    <template v-if="factura">
                                        <h2>Facturación</h2>
                                        <v-row class="my-0">
                                            <v-col cols="12" md="6" class="pb-0">
                                                <v-text-field
                                                    v-model="razonSocial"
                                                    :rules="required"
                                                    label="Razón Social"
                                                    required
                                                    color="#000000"
                                                    :disabled="paid"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6" class="pb-0">
                                                <v-text-field
                                                    v-model="rfc"
                                                    :rules="required"
                                                    label="RFC"
                                                    required
                                                    color="#000000"
                                                    :disabled="paid"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row class="my-0">
                                            <v-col cols="12" md="6" class="pb-0">
                                                <v-text-field
                                                    v-model="calleFactura"
                                                    :rules="required"
                                                    label="Calle"
                                                    required
                                                    color="#000000"
                                                    :disabled="paid"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6" class="pb-0">
                                                <v-text-field
                                                    v-model="numeroFactura"
                                                    :rules="required"
                                                    label="Número"
                                                    required
                                                    color="#000000"
                                                    :disabled="paid"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row class="my-0">
                                            <v-col cols="12" md="6" class="pb-0">
                                                <v-text-field
                                                    v-model="coloniaFactura"
                                                    :rules="required"
                                                    label="Colonia"
                                                    required
                                                    color="#000000"
                                                    :disabled="paid"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6" class="pb-0">
                                                <v-text-field
                                                    v-model="codigoPostalFactura"
                                                    :rules="required"
                                                    label="Código Postal"
                                                    required
                                                    color="#000000"
                                                    :disabled="paid"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row class="my-0">
                                            <v-col cols="6" class="pb-0">
                                                <v-text-field
                                                    v-model="ciudadFactura"
                                                    :rules="required"
                                                    label="Ciudad / Municipio"
                                                    required
                                                    color="#000000"
                                                    :disabled="paid"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="pb-0">
                                                <v-text-field
                                                    v-model="estadoFactura"
                                                    :rules="required"
                                                    label="Estado"
                                                    required
                                                    color="#000000"
                                                    :disabled="paid"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <div class="mt-6">
                                        <v-btn
                                           plain
                                            class="button-primary ml-auto mr-auto"
                                            height="45"
                                            width="350"
                                            max-width="100%"
                                            @click="validateFormData"   
                                        >
                                            Continuar
                                        </v-btn>
                                    </div>
                                </v-form>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-row v-if="!paid" >
                                    <v-col id="paypal-div" cols="12" class="mt-10  text-center">
                                        
                                        <PayPal
                                            @payment-completed="payment_completed_cb"
                                            :amount="totalString"
                                            currency="MXN"
                                            :client="paypal"
                                            env="sandbox"
                                            :items="paypalItems"
                                            :button-style="paypalButtonStyle"
                                        >
                                        </PayPal>
                                    </v-col>
                                    <v-col cols="12" class="mt-15 ">
                                        <v-btn
                                            @click="e1 = 1"
                                            plain
                                            class="button-primary ml-auto mr-auto"
                                            height="45"
                                            width="300"
                                            max-width="100%"
                                            >
                                            Atrás
                                        </v-btn>
                                        <v-btn
                                            @click="payment_completed_cb"
                                            plain
                                            class="button-primary ml-auto mr-auto"
                                            height="45"
                                            width="300"
                                            max-width="100%"
                                            >
                                            3
                                        </v-btn>
                                      
                                    </v-col>
                                </v-row>
                            </v-stepper-content>

                            <v-stepper-content step="3">
                                <v-row>
                                    <v-col cols="12">
                                        <h2 class="text-center">¡Gracias por tu compra!</h2>
                                         <p class="mb-0 mt-3">
                                            En breve recibirás un correo con todos los detalles de la compra a <span class="bold">{{order.email}}</span>, para dar seguimiento a tu pedido.
                                            Cuando el pedido salga del almacén, te notificaremos con un correo.
                                        </p>
                                    </v-col>
                                    <v-col cols="12" class="mb-1">
                                        <p><b> Id del pedido:</b> {{order._id}} </p>
                                        
                                    </v-col>
                                </v-row>
                                <h2>Datos de contacto</h2>
                                <v-row class="my-0">
                                    <v-col cols="12" md="6" class="pb-0">
                                        <v-text-field
                                            v-model="order.firstName"
                                            label="Nombre"
                                            color="#000000"
                                            readonly
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="pb-0">
                                        <v-text-field
                                            v-model="order.lastName"
                                            label="Apellido"
                                            color="#000000"
                                            readonly
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="my-0">
                                    <v-col cols="12" md="6" class="pb-0">
                                        <v-text-field
                                            v-model="order.email"
                                            label="E-mail"
                                            color="#000000"
                                            readonly
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="pb-0">
                                        <v-text-field
                                            v-model="order.phoneNumber"
                                            label="Teléfono"
                                            color="#000000"
                                            readonly
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <h2>Datos de entrega</h2>
                                <v-row class="my-0">
                                    <v-col cols="12" md="6" class="pb-0">
                                        <v-text-field
                                            v-model="order.streetAddress"
                                            label="Calle"
                                            color="#000000"
                                            readonly
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="pb-0">
                                        <v-text-field
                                            v-model="order.city"
                                            label="Ciudad"
                                            color="#000000"
                                            readonly
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                    <v-row class="my-0">
                                    <v-col cols="12" md="6" class="pb-0">
                                        <v-text-field
                                            v-model="order.state"
                                            label="Estado"
                                            color="#000000"
                                            readonly
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="pb-0">
                                        <v-text-field
                                            v-model="order.zipCode"
                                            label="Código Postal"
                                            color="#000000"
                                            readonly
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <template v-if="factura">
                                    <h2>Facturación</h2>
                                    <v-row class="my-0">
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="order.razonSocial"
                                                label="Razón Social"
                                                color="#000000"
                                                readonly
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="order.rfc"
                                                label="RFC"
                                                color="#000000"
                                                readonly
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="my-0">
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="order.calleFactura"
                                                label="Calle"
                                                color="#000000"
                                                readonly
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="order.numeroFactura"
                                                label="Número"
                                                color="#000000"
                                                readonly
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="my-0">
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="order.coloniaFactura"
                                                label="Colonia"
                                                color="#000000"
                                                readonly
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="order.codigoPostalFactura"
                                                label="Código Postal"
                                                color="#000000"
                                                readonly
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="my-0">
                                        <v-col cols="6" class="pb-0">
                                            <v-text-field
                                                v-model="order.ciudadFactura"
                                                label="Ciudad / Municipio"
                                                color="#000000"
                                                readonly
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6" class="pb-0">
                                            <v-text-field
                                                v-model="order.estadoFactura"
                                                label="Estado"
                                                color="#000000"
                                                readonly
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </template>
                                <v-row>
                                    <v-col cols="12" class="mt-10">
                                       <router-link class="button-primary text-center ma-auto" :to="'/tienda'">
                                            Ir a la tienda
                                        </router-link>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </section>
            </v-col>
            <v-col cols="12" md="4">
                <section>
                    <div class="summary-boxes">
                        <h2>Resumen</h2>
                        <div>
                            <p>
                                Total de Artículos:
                            </p>
                            <p>
                                ${{numberWithCommas(subTotal)}}
                            </p>
                        </div>
                        <div>
                            <p>
                                Envio:
                            </p>
                            <p v-if="shippingAmount == 0">
                                --
                            </p>
                            <p v-else>
                                ${{shippingAmount}}
                            </p>
                        </div>
                        <hr />
                        <div>
                            <p>
                                Total:
                            </p>
                            <p>
                                ${{numberWithCommas(total)}}
                            </p>
                        </div>

                    </div>
                    <div class="summary-boxes">
                        <h2>Artículos</h2>
                        <v-card elevation="0" v-for="(product, index) in cartItems" :key="'item-'+index" class="mb-5 article-summary">
                            <v-row>
                                <v-col cols="auto" class="pa-0">
                                    <v-img  width="110"  aspect-ratio="1" :alt=product.name :src="'https://rawkv3.actstudio.xyz/storage/uploads'+product.image.path"></v-img>
                                </v-col>
                                <v-col class="cart-mini-body-info">
                                    <p>
                                        {{product.subCategory}}
                                           
                                    </p>
                                    <p>
                                        {{product.name}}
                                    </p>

                                    <p>
                                        {{product.size}}
                                        <span>-</span>
                                        ${{product.price}}
                                    </p>
                                </v-col>
                                <v-col cols="auto" class="text-center " >
                                    <p>Cantidad</p>
                                    <p>{{product.quantity}}</p>
                                </v-col>
                            </v-row>
                        </v-card>
                    </div>
                </section>
            </v-col>
        </v-row>
        
        <v-row  align="center" v-else>
            <v-col class="text-center mt-15 empty-checkout">
                <h2>No hay productos en el carrito.</h2>
                <router-link 
                    class="button-primary text-center ma-auto mt-15 link-no-items" 
                    :to="'/tienda'">
                    Ir a la tienda
                </router-link>
            </v-col>
        </v-row>
        
    </v-container>
</template>
<script>
import PayPal from 'vue-paypal-checkout';
export default {
    data() {
        return {
            e1:1,
            cartAfterPurchase: [],
            shippingEntries: [],
            valid: false,
            validPayment: false,
            disablePaymentForm: false,
            firstName: '',
            lastName: '',
            streetAddress: '',
            city: '',
            state: '',
            email: '',
            phoneNumber: '',
            zipCode: '',
            shippingAmount: 0,
            factura: false,
            razonSocial: '',
            rfc: '',
            calleFactura: '',
            numeroFactura: '',
            coloniaFactura: '',
            codigoPostalFactura: '',
            ciudadFactura: '',
            estadoFactura: '',
            selected: null,
            required: [(v) => !!v || "* required"],
            requiredSelect: [(v) =>  v.length>0 || "* required"],
            emailRules: [
                (v) => !!v || "* required",
                (v) => /.+@.+\..+/.test(v) || "* Correo inválido",
            ],
            paid: false,
            order: {
                _id: '',
                email: ''
            },
            paypal: {
                sandbox: 'AUrnLrDVAvubSb2RsipK7HOam99rx5l1xAoadYLExE-xHaryccurkGKPNSaIP4hp-bW5AVMLKoowc0BM',
                production: '<production client id>'
            },
            paypalButtonStyle: {
                label: 'checkout',
                size:  'responsive',   
                shape: 'pill',      
                color: 'silver' 
            },
            states: [
                {state: 'Aguascalientes'},
                {state: 'Baja California'},
                {state: 'Baja California Sur'},
                {state: 'Campeche'},
                {state: 'Chiapas'},
                {state: 'Chihuahua'},
                {state: 'Ciudad de México'},
                {state: 'Coahuila'},
                {state: 'Colima'},
                {state: 'Durango'},
                {state: 'Guanajuato'},
                {state: 'Guerrero'},
                {state: 'Hidalgo'},
                {state: 'Jalisco'},
                {state: 'Michoacán'},
                {state: 'Morelos'},
                {state: 'México'},
                {state: 'Nayarit'},
                {state: 'Nuevo León'},
                {state: 'Oaxaca'},
                {state: 'Puebla'},
                {state: 'Querétaro'},
                {state: 'Quintana Roo'},
                {state: 'San Luis Potosí'},
                {state: 'Sinaloa'},
                {state: 'Sonora'},
                {state: 'Tabasco'},
                {state: 'Tamaulipas'},
                {state: 'Tlaxcala'},
                {state: 'Veracruz'},
                {state: 'Yucatán'},
                {state: 'Zacatecas'},

            ]
        }
    },
    components: {
        PayPal
    },
    props: {
        cart: Array
    },
    beforeMount: function(){
        fetch('https://rawkv3.actstudio.xyz/api/content/items/ShippingCost', {
            method: 'GET',
        })
        .then(res=> res.json())
        .then(res => this.shippingEntries = res);
    },
    methods: {
        numberWithCommas: function(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        validateFormData: function() {
            if (this.$refs.form.validate()) {
                this.e1 = 2;
            }
        },
        calculateShipping: function(){
            this.shippingEntries.forEach(elem =>{
                if(elem.estado == this.state){
                    this.shippingAmount = parseInt(elem.costo);
                }
            });
        },
        payment_completed_cb: function(res, planName){
            fetch('https://rawkv3.actstudio.xyz/api/content/item/Orders', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    data: this.paypalOrder
                })
            })
            .then(res=>res.json())
            .then(entry => {
                this.cartAfterPurchase = this.cart;
                this.order = entry;
                this.paid = true;
                this.e1 = 3;
                this.reduceInventory();
            });
        },

        reduceInventory: function(){
            fetch('https://rawkv3.actstudio.xyz/api/content/items/ProductInventory?populate=2', {
                method: 'GET',

            })
            .then(res=> res.json())
            .then(res => {
                let productInventory = res;

                this.cart.forEach(item => {
                    let currentProductInventory = productInventory.filter(elem => elem._id == item._id );
                    currentProductInventory[0].inventory = parseInt(currentProductInventory[0].inventory) - item.quantity;
                    fetch('https://rawkv3.actstudio.xyz/api/content/item/ProductInventory', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            data: currentProductInventory[0]
                        })
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });

            this.$root.$emit('clearCart');
        }
    },
    computed: {
        cartItems: function(){
            let cartItems = [];
            if(this.cart.length > 0){
                cartItems = this.cart;
            }else{
                cartItems = this.cartAfterPurchase;
            }

            return cartItems;
        },

        subTotal: function(){
            let total = 0;
            if(this.cart.length > 0){
                this.cart.forEach(item => {
                    total += item.quantity * item.price;
                });
            }else{
                this.cartAfterPurchase.forEach(item => {
                    total += item.quantity * item.price;
                });
            }
            return total;
        },

        total: function(){
            return this.subTotal + this.shippingAmount;
        },

        totalString: function(){
            return this.total.toString();
        },

        paypalItems: function(){
            let ppcart = [];

            this.cart.forEach(product => {
                ppcart.push({
                    name: product.subCategory,
                    description: product.size + ' - ' +product.name,
                    quantity: product.quantity,
                    price: product.price,
                    currency: 'MXN'
                })
            });

            ppcart.push({
                name: "Envio",
                quantity: 1,
                price: this.shippingAmount,
                currency: "MXN",
            });

            return ppcart;

        },

        paypalOrder: function(){
            let orderDetails = {
                status: 'Recibido',
                subTotalPrice: this.subTotal,
                shippingPrice: this.shippingAmount,
                totalOrderPrice: this.total,
                firstName: this.firstName,
                lastName: this.lastName,
                streetAddress: this.streetAddress,
                city: this.city,
                state: this.state,
                email: this.email,
                phoneNumber: this.phoneNumber,
                zipCode: this.zipCode,
                factura: this.factura,
                rfc: this.rfc,
                calleFactura: this.calleFactura,
                numeroFactura: this.numeroFactura,
                coloniaFactura: this.coloniaFactura,
                codigoPostalFactura: this.codigoPostalFactura,
                ciudadFactura: this.ciudadFactura,
                estadoFactura: this.estadoFactura,
                pagadoCon: 'Paypal',
                details: []
            };

            let items = [];

            this.cart.forEach(product => {
                let product_cockpit = {
                    productImage: product.image.path,
                    productName: product.name,
                    productCategory: product.subCategory,
                    size: product.size,
                    quantity: product.quantity,
                    unitPrice: product.price
                }

                items.push(product_cockpit);
            });

            orderDetails.details = items;
            return orderDetails;
        }
    },
}
</script>
